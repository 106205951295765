<template>
  <g :fill="color" fill-rule="nonzero" transform="translate(-8, -8) scale(0.05, 0.05)">
    <g id="Group_11381" data-name="Group 11381">
      <path
        id="Path_29278"
        :fill="color"
        data-name="Path 29278"
        d="M460.63,339.41c-16.07-16.11-37.86-25.22-60.62-25.33-22.79-.09-44.65,9.05-60.61,25.33-33.48,33.64-33.35,88.05.29,121.53,16.03,15.95,37.7,24.95,60.32,25.03,22.79.09,44.65-9.05,60.61-25.33,16.11-16.07,25.21-37.85,25.33-60.61.1-22.8-9.03-44.66-25.31-60.62ZM444.35,444.34c-24.49,24.98-64.59,25.38-89.57.88-24.98-24.49-25.38-64.59-.88-89.57,24.49-24.98,64.59-25.38,89.57-.88,12.15,11.92,19,28.22,19,45.25.08,16.59-6.44,32.54-18.13,44.33h.01Z"
      />
    </g>
    <g id="Group_11383" data-name="Group 11383">
      <path
        id="Path_29279"
        data-name="Path 29279"
        :fill="color"
        d="M559.19,388.26h-34.83c-6.5,0-11.76,5.27-11.76,11.76s5.27,11.76,11.76,11.76h34.83c6.5,0,11.76-5.27,11.76-11.76s-5.27-11.76-11.76-11.76Z"
      />
    </g>
    <g id="Group_11385" data-name="Group 11385">
      <path
        id="Path_29280"
        data-name="Path 29280"
        :fill="color"
        d="M400.01,512.64c-6.44.12-11.64,5.31-11.75,11.75v34.8c0,6.5,5.27,11.76,11.76,11.76s11.76-5.27,11.76-11.76v-34.83c-.13-6.44-5.33-11.62-11.77-11.73Z"
      />
    </g>
    <g id="Group_11387" data-name="Group 11387">
      <path
        id="Path_29281"
        data-name="Path 29281"
        :fill="color"
        d="M520.77,504.49l-24.88-24.88c-4.5-4.5-11.78-4.5-16.28,0-4.5,4.5-4.5,11.78,0,16.28l24.88,24.88c4.5,4.5,11.78,4.5,16.28,0,4.5-4.5,4.5-11.78,0-16.28Z"
      />
    </g>
    <g id="Group_11389" data-name="Group 11389">
      <path
        id="Path_29282"
        data-name="Path 29282"
        :fill="color"
        d="M400.01,229.05c-6.44.12-11.64,5.31-11.75,11.75v34.8c0,6.5,5.27,11.76,11.76,11.76s11.76-5.27,11.76-11.76v-34.8c-.12-6.45-5.32-11.65-11.77-11.75Z"
      />
    </g>
    <g id="Group_11391" data-name="Group 11391">
      <path
        id="Path_29283"
        data-name="Path 29283"
        :fill="color"
        d="M521.22,279.25c-4.46-4.5-11.72-4.53-16.21-.07-.02.02-.04.04-.07.07l-24.88,24.88c-4.5,4.5-4.5,11.78,0,16.28,4.5,4.5,11.78,4.5,16.28,0h0l24.88-24.88c4.5-4.46,4.53-11.72.07-16.21-.02-.02-.04-.04-.07-.07Z"
      />
    </g>
    <g id="Group_11393" data-name="Group 11393">
      <path
        id="Path_29284"
        data-name="Path 29284"
        :fill="color"
        d="M275.6,388.26h-34.8c-6.44.12-11.64,5.31-11.75,11.75-.06,6.43,5.09,11.69,11.52,11.75.08,0,.16,0,.23,0h34.8c6.5,0,11.76-5.27,11.76-11.76s-5.27-11.76-11.76-11.76h0Z"
      />
    </g>
    <g id="Group_11395" data-name="Group 11395">
      <path
        id="Path_29285"
        data-name="Path 29285"
        :fill="color"
        d="M319.96,479.62c-4.46-4.5-11.72-4.53-16.21-.07-.02.02-.04.04-.07.07l-24.88,24.88c-4.5,4.5-4.5,11.78,0,16.28,4.5,4.5,11.78,4.5,16.28,0l24.88-24.88c4.5-4.46,4.53-11.72.07-16.21-.02-.02-.04-.04-.07-.07Z"
      />
    </g>
    <g id="Group_11397" data-name="Group 11397">
      <path
        id="Path_29286"
        data-name="Path 29286"
        :fill="color"
        d="M319.96,304.13l-24.88-24.88c-4.5-4.5-11.78-4.5-16.28,0-4.5,4.5-4.5,11.78,0,16.28l24.88,24.88c4.5,4.5,11.78,4.5,16.28,0,4.5-4.5,4.5-11.78,0-16.28Z"
      />
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
